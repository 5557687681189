import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { FaSun, FaMoon } from 'react-icons/fa';
import Typewriter from 'typewriter-effect/dist/core';

import './App.css';

const App = () => {
  const [question, setQuestion] = useState('');
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [darkMode, setDarkMode] = useState(false);

  const handleQuestion = async (e) => {
    e.preventDefault();
    setLoading(true);
    const chatGPTResponse = await getChatGPTResponse(question);
    setResponse(chatGPTResponse);
    setLoading(false);
  };

  const handleExplorePrompt = async (prompt) => {
    setLoading(true);
    const chatGPTResponse = await getChatGPTResponse(prompt);
    setResponse(chatGPTResponse);
    setLoading(false);
  };

  const getChatGPTResponse = async (prompt) => {
    try {
      const response = await axios.post(
        'https://v7nsu8zkfk.execute-api.us-east-1.amazonaws.com/prod',
        {
          prompt: `You are the best guitar player in the world and you know everything there is to know about guitars, you must only answer questions related to guitars, if a question is not guitar related answer as follows: I can only answer guitar related questions. \n\n${prompt}`,
          max_tokens: 150,
        },
      );

      return response.data.choices[0].text.trim();
    } catch (error) {
      console.error('Error fetching ChatGPT response:', error);
      return 'An error occurred while fetching the response.';
    }
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <div className={`app ${darkMode ? 'dark-mode' : 'light-mode'}`}>
      <div className="toggle-dark-mode" onClick={toggleDarkMode}>
        {darkMode ? <FaSun size={24} /> : <FaMoon size={24} />}
      </div>
      <MainInterface
        handleQuestion={handleQuestion}
        handleExplorePrompt={handleExplorePrompt}
        question={question}
        setQuestion={setQuestion}
        response={response}
        loading={loading}
      />
      <footer>
        Made with{' '}
        <span role="img" aria-label="love">
          ❤️
        </span>{' '}
        by GuitarHead.io
      </footer>
    </div>
  );
};

const MainInterface = ({ handleQuestion, handleExplorePrompt, question, setQuestion, response, loading }) => {
  const responseRef = useRef(null);

  useEffect(() => {
    if (response && !loading) {
      const typewriter = new Typewriter(responseRef.current, {
        delay: 25,
        cursor: '',
        deleteSpeed: 1,
      });
      typewriter
        .pauseFor(500)
        .typeString(response)
        .start();
    }
  }, [response, loading]);

  const explorePrompts = [
    'How do I tune my guitar in drop D?',
    'What are the best guitar strings for a beginner?',
    'How can I learn to play barre chords?',
    'How often should I change my guitar strings?',
    'What is the difference between an acoustic and an electric guitar?',
  ];

  return (
    <>
      <h1>GuitarHead.io</h1>
      <form onSubmit={handleQuestion} className="question-form">
      <div className="form-group">
          <input
            type="text"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            required
            placeholder="Ask anything about guitars..."
          />
        </div>
        <button type="submit" className="submit-btn">
          Ask
        </button>
      </form>
      {loading ? (
        <div className="loading-animation">
          <img src="/loading.gif" alt="Loading..." />
        </div>
      ) : (
        <div className="response-area">
          <p ref={responseRef}></p>
        </div>
      )}
      <div className="explore">
        <h2>Explore</h2>
        <div className="explore-buttons">
          {explorePrompts.map((prompt, index) => (
            <button key={index} onClick={() => handleExplorePrompt(prompt)}>
              {prompt}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

export default App;

